/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import "./style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { Button } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

const Sidebar = (props) => {
  let {
    parameterGroups,
    validateGroupSelection,
    locationData,
    assetData,
    parameterData,
    changeLocation,
    changeAsset,
    changeParameter,
    handleSubmitData,
    selectedLocation,
    selectedAsset,
    selectedParameter,
    fromDate,
    toDate,
    setFromDate,
    setToDate,
    isParam,
    changeIsParam,
    groupData,
    handleAddGroup,
    getGroupName,
    updateFromDate,
    updateToDate,
  } = props;
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [fromTime, setFromTime] = useState("00:00");
  const [fromHours, setFromHours] = useState(
    fromDate.getHours().toString().padStart(2, "0")
  );
  const [fromMinutes, setFromMinutes] = useState(
    fromDate.getMinutes().toString().padStart(2, "0")
  );
  const [fromSeconds, setFromSeconds] = useState("00");
  const [toTime, setToTime] = useState("00:00");
  const [toHours, setToHours] = useState(
    toDate.getHours().toString().padStart(2, "0")
  );
  const [toMinutes, setToMinutes] = useState(
    toDate.getMinutes().toString().padStart(2, "0")
  );
  const [toSeconds, setToSeconds] = useState("00");

  // Custom input component to disable manual typing
  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      className="form-control"
      value={value}
      onClick={onClick}
      ref={ref}
      readOnly
    />
  ));

  const validationSchema = Yup.object().shape({
    // fromDate: Yup.date()
    //   .required('From Date is required')
    //   .max(new Date(moment(toDate).add(1, 'days')), 'From Date should be less than or equal to To Date')
    //   .min(new Date(moment(fromDate).subtract(30, 'days')), 'From Date is exceeding the minimum From Date'),
    // .max(new Date(), 'From Date should be less than or equal to the current date'),
    // toDate: Yup.date()
    //   .required('To Date is required')
    //   .min(new Date(moment(fromDate).subtract(1, 'days')), 'To Date should be greater than or equal to From Date')
    //   .max(new Date(moment(fromDate).add(3, 'months')), 'To Date is exceeding the maximum To Date')
    // ,

    location: Yup.object().shape({
      value: Yup.string().required("Location is required"),
    }),

    site: Yup.object().shape({
      value: Yup.string().required("Site is required"),
    }),

    isParam: Yup.boolean().notRequired(),

    parameter: Yup.array()
      .when("isParam", {
        is: false, // Apply validation only when "isParam" is false
        then: (validationSchema) =>
          validationSchema
            .required("Parameter is required")
            .min(1, "At least one parameter must be selected")
            .test(
              "max-2-groups",
              "You can only select parameters from up to 2 groups.",
              (selectedParams) => {
                if (!selectedParams || selectedParams.length === 0) return true; // Pass if empty
                const groups = [...new Set(selectedParams.map((param) => param.group))];
                return groups.length <= 2; // Pass if 2 or fewer groups are selected
              }
            )
            .test(
              "limit-selection-in-groups",
              "Cannot select a parameter from another group if two groups are already selected.",
              (selectedParams) => {
                if (!selectedParams || selectedParams.length === 0) return true; // Pass if empty
                const groupCounts = selectedParams.reduce((acc, param) => {
                  acc[param.group] = (acc[param.group] || 0) + 1;
                  return acc;
                }, {});
                const selectedGroups = Object.keys(groupCounts);
                return selectedGroups.length <= 2; // Pass if 2 or fewer groups are selected
              }
            ),
        otherwise: (validationSchema) => validationSchema.notRequired(),
      })
      .nullable(),




    group: Yup.object()
      .shape({})
      //
      .when("isParam", {
        is: true,
        then: (validationSchema) =>
          validationSchema.required("Group is required"),
        otherwise: (validationSchema) => validationSchema.notRequired(),
      }),
  });

  const formik = useFormik({
    initialValues: {
      // fromDate: fromDate,
      toDate: toDate,
      location: selectedLocation,
      site: selectedAsset,
      parameter: selectedParameter || [],
      isParam: isParam,
      group: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const fromDateWithHours = new Date(values.fromDate);
      fromDateWithHours.setHours(fromHours, fromMinutes, fromSeconds);

      const toDateWithHours = new Date(values.toDate);
      toDateWithHours.setHours(toHours, toMinutes, toSeconds);

      // Check if To Date is less than From Date
      if (toDateWithHours < fromDateWithHours) {
        formik.setFieldError('toDate', 'To Date cannot be less than From Date.');
        return;
      }

      // Check the difference between fromDate and toDate
      const diffInDays = moment(toDateWithHours).diff(moment(fromDateWithHours), 'days');

      // If difference exceeds 30 days, show error message
      if (diffInDays > 30) {
        formik.setFieldError('toDate', 'Maximum allowed date range is 30 days');
      } else {

        const data = {
          fromDate: fromDateWithHours,
          toDate: toDateWithHours,
          // toDate: new Date(toDate),
          location: selectedLocation,
          site: selectedAsset,
          parameter: selectedParameter,
          group: values.group,
        };
        props.handleSubmitData(data);
        handleCloseFirstModal();

        props.updateFromDate(fromDateWithHours);
        props.updateToDate(toDateWithHours);
      }
    },
  });

  const groupFormik = useFormik({
    // enableReinitialize: true, // Enable reinitialization when initialValues change
    initialValues: {
      group_name: "",
    },
    validationSchema: Yup.object().shape({
      group_name: Yup.string()
        .max(25, "Title must be equal to or less than 25 characters")
        .required("Title is required"),
    }),
    onSubmit: (values) => {
      handleAddGroup(values.group_name);
      handleCloseSecondModal();
    },
  });

  const handleCloseFirstModal = () => {
    setShowFirstModal(false);
  };
  const handleShowFirstModal = () => {
    formik.setErrors({
      fromDate: "",
      toDate: "",
      location: "",
      site: "",
      parameter: "",
    });
    formik.setFieldValue("fromDate", fromDate);
    formik.setFieldValue("location", selectedLocation);
    formik.setFieldValue("site", selectedAsset);
    formik.setFieldValue("parameter", selectedParameter || []);
    setShowFirstModal(true);
  };

  const handleCloseSecondModal = () => {
    setShowSecondModal(false);
  };
  const handleShowSecondModal = () => {
    setShowSecondModal(true);
  };

  const handleCloseTitleGroupForm = () => {
    setShowSecondModal(false);
  };

  const handleLocationChange = (selectedLocation) => {
    localStorage.removeItem("asset");
    props.changeLocation(selectedLocation);
  };

  const changeToggleButton = (checked) => {
    let toggleParamColorElement = document.getElementById("toggleParamColor");
    let spanElements = toggleParamColorElement.querySelectorAll("span");
    if (checked) {
      spanElements[0].style.color = "black";
      spanElements[1].style.color = "white";
    } else {
      spanElements[1].style.color = "black";
      spanElements[0].style.color = "white";
    }
  };
  const handleParameterChange = (selectedOptions) => {
    const selectedGroups = [...new Set(selectedOptions.map((param) => param.group))];
    if (selectedGroups.length > 2) {
      alert("Only two parameter groups can be selected at a time.");
      return;
    }
    changeParameter(selectedOptions);
    formik.setFieldValue("parameter", selectedOptions);
  };
  useEffect(() => {
    formik.validateField("parameter");
  }, [formik.values.parameter]);

  return (
    <>
      <div className="sidebarRight">
        <ul>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="modal"
              href="#"
              data-bs-target="#alert"
              data-tooltip-id="tooltip"
              data-tooltip-content="Alert"
            >
              <i className="fa fa-bell-o nav-icon"></i>
            </a>
            <Tooltip
              id="tooltip"
              place="bottom"
              type="dark"
              effect="solid"
              style={{
                position: "absolute",
                marginLeft: "-10px",
                padding: "10% 20% 10% 20%",
              }}
            />
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              onClick={handleShowFirstModal}
              data-tooltip-id="tooltip"
              data-tooltip-content="Filter"
            >
              <i className="fa fa-filter nav-icon"></i>
            </a>
          </li>
        </ul>
      </div>
      {/* filter modal */}
      <Modal
        show={showFirstModal}
        onHide={handleCloseFirstModal}
        className="right"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <b>Filter</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} id="energy_consumtion_form">
            <div className="form-row">
              <div className="col-md-12">
                {/* <div className="selectWpr"> */}

                {/* is param toggle */}
                <div
                  className="form-group switch float-left"
                // onChange={getGroupName}
                >
                  <label className="switch-light">
                    <input
                      type="checkbox"
                      checked={formik.values.isParam}
                      onChange={(e) => {
                        formik.setFieldValue("isParam", e.target.checked);
                        changeIsParam(e.target.checked);
                        changeToggleButton(e.target.checked);
                      }}
                      onBlur={() => {
                        // formik.handleBlur('site');
                        formik.setFieldTouched("isParam", true);
                      }}
                      name="isGroupSwitchOn"
                    />
                    {/* <span>
                      <span>Parameter</span>
                      <span>Group</span>
                    </span> */}
                    <span id="toggleParamColor">
                      <span >Parameter</span>
                      <span>Group</span>
                    </span>
                    <a className="btn btn-primary"></a>
                  </label>
                </div>

                {/* add group */}
                {formik.values.isParam === false && (
                  <div className="float-right form-group">
                    <span
                      onClick={(e) =>
                        formik.values.parameter.length < 2 && setDisabled(true)
                      }
                    >
                      <Button
                        className="btn btn-sm btn-c-outline-primary"
                        onClick={handleShowSecondModal}
                        disabled={
                          formik.values.isParam === false &&
                          formik.values.parameter.length <= 1
                        }
                      >
                        Add Group
                      </Button>
                    </span>
                  </div>
                )}

                <br />

                <div className="col-md-12" Style={"clear: both"}></div>

                {/* location */}
                <div className="form-group">
                  <label>Location *</label>
                  <Select
                    options={locationData}
                    placeholder="Select Location"
                    onChange={(e) => {
                      handleLocationChange(e);
                      formik.setFieldValue("site", "");
                      // changeLocation(e);
                      formik.setFieldTouched("location", true);
                      formik.setFieldValue("location", e);
                    }}
                    value={formik.values.location}
                    onBlur={() => {
                      // formik.handleBlur('location');
                      formik.setFieldTouched("location", true);
                    }}
                  />
                  {formik.errors.location && formik.touched.location && (
                    <div className="error-message text-danger">
                      {formik.errors.location.value}
                    </div>
                  )}
                </div>
                {/* site */}
                <div className="form-group">
                  <label>Asset *</label>
                  <Select
                    options={assetData}
                    placeholder="Select Site"
                    value={formik.values.site}
                    onChange={(e) => {
                      formik.setFieldValue("parameter", []);
                      changeAsset(e);
                      formik.setFieldTouched("site", true);
                      formik.setFieldValue("site", e);
                    }}
                    onBlur={() => {
                      // formik.handleBlur('site');
                      formik.setFieldTouched("site", true);
                    }}
                  />
                  {formik.errors.site && formik.touched.site && (
                    <div className="error-message text-danger">
                      {formik.errors.site.value}
                    </div>
                  )}
                  {/* </div> */}
                </div>

                {/* parameters */}
                <div className="col-md-12" Style={"clear: both"}></div>
                {formik.values.isParam === false && (
                  <div className="form-group fixHeight">
                    <label>Parameter *</label>
                    <Select
                      isMulti={true}
                      closeMenuOnSelect={false} // Keep dropdown open after selection
                      options={Object.entries(parameterData).map(([group, params]) => ({
                        label: group,
                        options: params.map((param) => ({
                          label: param.label,
                          value: param.value,
                          group: group,
                          isDisabled:
                            formik.values.parameter
                              .map((selected) => selected.group)
                              .filter((value, index, self) => self.indexOf(value) === index)
                              .length >= 2 &&
                            !formik.values.parameter.some((selected) => selected.group === group),
                        })),
                      }))}
                      placeholder="Select Parameter"
                      value={formik.values.parameter}
                      onChange={(selected) => {
                        console.log("Selected Parameters:", selected); // Debugging
                        changeParameter(selected); // Call external change handler
                        formik.setFieldValue("parameter", selected, false); // Update state without immediate validation
                        formik.validateField("parameter"); // Manually trigger validation
                      }}
                      onBlur={() => {
                        formik.setFieldTouched("parameter", true);
                      }}
                    />


                    {formik.errors.parameter &&
                      !formik.values.parameter.length <= 1 &&
                      formik.touched.parameter && (
                        <div className="error-message text-danger">
                          {formik.errors.parameter}
                        </div>
                      )}

                    {formik.values.parameter.length <= 1 &&
                      disabled &&
                      !formik.errors.parameter && (
                        <div className="error-message text-danger">
                          Group can be created; it requires more than one parameter.
                        </div>
                      )}
                  </div>
                )}


                {formik.values.isParam === true && (
                  <div className="form-group">
                    <label>Group *</label>
                    <Select
                      options={groupData}
                      placeholder="Select Group"
                      onChange={(e) => {
                        formik.setFieldTouched("group", true);
                        formik.setFieldValue("group", e);
                      }}
                      value={formik.values.group}
                      onBlur={() => {
                        formik.setFieldTouched("group", true);
                      }}
                    />
                    {formik.errors.group && (
                      <div className="error-message text-danger">
                        {formik.errors.group}
                      </div>
                    )}
                  </div>
                )}

                {/* from date */}
                <div className="form-group">
                  <div className="row">
                    <div className="col-8">
                      <label>From Date *</label>
                      <DatePicker
                        selected={formik.values.fromDate}
                        onChange={(date) => {
                          if (moment(date, "DD-MM-YYYY", true).isValid()) {
                            formik.setFieldValue("fromDate", date);
                            formik.setFieldTouched("fromDate", true);
                            updateFromDate(date);
                            // Remove auto-update logic for "To Date"
                            // const lastDate = new Date(moment(date).add(30, 'days'));
                            // formik.setFieldValue('toDate', lastDate);
                            // formik.setFieldTouched('toDate', true);
                            // updateToDate(lastDate);
                          }
                        }}
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        maxDate={new Date()} // Maximum date as today
                        minDate={new Date(moment().subtract(3, "months"))} // Minimum date 3 months ago
                        customInput={<CustomDateInput />}
                      />
                    </div>

                    <div className="col-2">
                      <label>HH</label>
                      <select
                        className="form-control"
                        value={fromHours}
                        onChange={(e) => setFromHours(e.target.value)}
                      >
                        {Array.from({ length: 24 }, (_, i) => {
                          const hour = i.toString().padStart(2, "0");
                          return (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-2">
                      <label>MM</label>
                      <select
                        className="form-control"
                        value={fromMinutes}
                        onChange={(e) => setFromMinutes(e.target.value)}
                      >
                        {Array.from({ length: 60 }, (_, i) => {
                          const minute = i.toString().padStart(2, "0");
                          return (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-2" style={{ display: "none" }}>
                      <label>SS</label>
                      <select
                        className="form-control"
                        value={fromSeconds}
                        onChange={(e) => setFromSeconds(e.target.value)}
                      >
                        {Array.from({ length: 60 }, (_, i) => {
                          const second = i.toString().padStart(2, "0");
                          return (
                            <option key={second} value={second}>
                              {second}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {/* {formik.errors.fromDate && formik.touched.fromDate && (
                      <div className="error-message text-danger">
                        {formik.errors.fromDate}
                      </div>
                    )} */}
                  </div>
                </div>
                {/* to date */}
                <div className="form-group">
                  <div className="row">
                    <div className="col-8">
                      <label>To Date *</label>
                      <DatePicker
                        selected={formik.values.toDate}
                        onChange={(date) => {
                          if (moment(date, "DD-MM-YYYY", true).isValid()) {
                            formik.setFieldValue("toDate", date);
                            formik.setFieldTouched("toDate", true);
                            updateToDate(date);
                            // setToDate(date);
                          }
                        }}
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        includeDateIntervals={[
                          {
                            start: new Date(
                              moment(formik.values.fromDate).subtract(3, "months")
                            ),
                            end: new Date(
                              moment(formik.values.fromDate).add(3, "months")
                            ),
                          },
                        ]}
                        onBlur={(e) => {
                          try {
                            if (
                              moment(
                                e.target.value,
                                "DD-MM-YYYY",
                                true
                              ).isValid()
                            ) {
                              const date = new Date(
                                moment(e.target.value, "DD-MM-YYYY").format(
                                  "YYYY-MM-DD"
                                )
                              );
                              formik.setFieldValue("toDate", date);
                              formik.setFieldTouched("toDate", true);
                              setToDate(date);
                            }
                          } catch (error) {}
                        }}
                        maxDate={new Date()} // Adjust if you want a different maximum date logic
                        minDate={new Date(moment().subtract(3, "months"))} // Minimum date 3 months ago
                        customInput={<CustomDateInput />}
                      />
                    </div>
                    <div className="col-2">
                      <label>HH</label>
                      <select
                        className="form-control"
                        value={toHours}
                        onChange={(e) => setToHours(e.target.value)}
                      >
                        {Array.from({ length: 24 }, (_, i) => {
                          const hour = i.toString().padStart(2, "0");
                          return (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-2">
                      <label>MM</label>
                      <select
                        className="form-control"
                        value={toMinutes}
                        onChange={(e) => setToMinutes(e.target.value)}
                      >
                        {Array.from({ length: 60 }, (_, i) => {
                          const minute = i.toString().padStart(2, "0");
                          return (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-2" style={{ display: "none" }}>
                      <label>SS</label>
                      <select
                        className="form-control"
                        value={toSeconds}
                        onChange={(e) => setToSeconds(e.target.value)}
                      >
                        {Array.from({ length: 60 }, (_, i) => {
                          const second = i.toString().padStart(2, "0");
                          return (
                            <option key={second} value={second}>
                              {second}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {formik.errors.toDate && formik.touched.toDate && (
                      <div className="error-message text-danger text-center">
                        {formik.errors.toDate}
                      </div>
                    )}
                  </div>
                </div>
                <div className="ftrBtn">
                  <div className="form-group">
                    <input
                      type="submit"
                      name=""
                      value="Submit"
                      className="btn btn-primary btn-block"
                      data-disable-with="Submit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* add group modal */}
      <Modal
        show={showSecondModal}
        onHide={handleCloseSecondModal}
        data-bs-backdrop="static"
        className="rightGroup"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Add Title To Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={groupFormik.handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Title"
                name="groupName"
                value={groupFormik.values.group_name}
                // onBlur={formik.handleBlur}
                onChange={(e) => {
                  groupFormik.setFieldValue("group_name", e.target.value);
                }}
                onBlur={() => {
                  groupFormik.setFieldTouched("group_name", true);
                }}
              />
              {groupFormik.errors.group_name && (
                <div className="error-message text-danger">
                  {groupFormik.errors.group_name}
                </div>
              )}
            </div>
            <div className="">
              <div className="form-group">
                <input
                  type="submit"
                  name=""
                  value="Save"
                  className="btn btn-primary btn-block"
                  // disabled={!formik.isValid}
                  data-disable-with="Submit"
                  // checked={formik.values.isGroupSwitchOn}
                  // onChange={formik.handleChange}
                  onClick={(e) => {
                    // e.preventDefault();
                    // handleSaveGroup(formik.values.groupName);
                    handleCloseTitleGroupForm();
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Sidebar;
