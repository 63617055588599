/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
// import ReactSpeedometer from "react-d3-speedometer";
import Cookies from "js-cookie";
import Sidebar from "./Sidebar";
import { Alert } from "react-bootstrap";
import moment from "moment";
import Plot from "react-plotly.js";

function Fleet() {
  const [isLoading, setIsLoading] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [assetData, setAssetData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [onloadLoc, setOnloadLoc] = useState("");
  const [onloadAsset, setOnloadAsset] = useState("");
  const [onload, setOnLoad] = useState(false);
  const [token, setToken] = useState("");
  const [userLogId, setUserLogId] = useState("");
  const [isChartDataAvailable, setIsChartDataAvailable] = useState(true);
  const [alerts, setAlerts] = React.useState([]);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const [chartSeries, setChartSeries] = useState([
    
      {
        name: "winding temp",
        data: [
          [1, 1], 
          [2, 2], 
          [3, 3], 
          [4, 5], 
          [5, 4], 
          [6, 3], 
          [7, 2], 
          [8, 1], 
          
        ],
      },
      {
        name: "oil temp",
        data: [
          [1, 2], 
          [2, 3], 
          [3, 4], 
          [4, 5], 
          [5, 2], 
          [6, 3], 
          [7, 4], 
          [8, 5], 
           
        ],
      },
      {
        name: "busbar temp",
        data: [
          [1, 9], 
          [2, 8], 
          [3, 6], 
          [4, 5], 
          [5,3], 
          [6, 7], 
          [7, 0], 
          [8, 5], 
           
        ],
      },
      
      {
        name: "ambient ",
        data: [
          [1, 13], 
          [2, 13], 
          [3, 14], 
          [4, 15], 
          [5, 12], 
          [6, 13], 
          [7, 14], 
          [8, 15], 
          
        ],
      }
    ]);
  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });
  const [chartOption, setChartOption] = useState({
    colors: [
      "#39B54A",
      "#F15A2A",
      "#2A73B9",
      "#F7941E",
     
    ],
    chart: {
      toolbar: {
      show: false, // Disables all toolbar icons
    },
      // toolbar: {
      //   export: {
      //     csv: {
      //       filename: "Transformer Trend",
      //       headerCategory: "Date Time",
      //       categoryFormatter: function (timestamp) {
      //         let date = new Date(timestamp).toUTCString();
      //         const originalDate = new Date(date);

      //         // Add 5 hours and 30 minutes to the original date
      //         originalDate.setHours(originalDate.getHours() - 5);
      //         originalDate.setMinutes(originalDate.getMinutes() - 30);

      //         // Format the date and time in "YYYY-MM-DD HH:mm:ss" format
      //         const formattedDate =
      //           ("0" + originalDate.getDate()).slice(-2) +
      //           "-" +
      //           ("0" + (originalDate.getMonth() + 1)).slice(-2) +
      //           "-" +
      //           originalDate.getFullYear() +
      //           " " +
      //           ("0" + originalDate.getHours()).slice(-2) +
      //           ":" +
      //           ("0" + originalDate.getMinutes()).slice(-2) +
      //           ":" +
      //           ("0" + originalDate.getSeconds()).slice(-2);
      //         return formattedDate;
      //       },
      //     },
      //     png: {
      //       filename: "Transformer Trend",
      //     },
      //     svg: {
      //       filename: "Transformer Trend",
      //     },
      //   },
      // },
      animations: {
        enabled: false,
      },
      height: "350px",
      width: "100%",

      zoom: {
        enabled: true,
      },
    },
    legend: {
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "center",
      fontFamily: "Montserrat, sans-serif", // Set font family for legend
      fontSize: "14px", // Set font size for legend
      fontWeight: 600,
    },
    // legend: {
    //   position: 'top',
    //   horizontalAlign: 'center',
    //   // fontSize: '14px'
    // },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      row: {
        opacity: 0.5,
      },
    },
    yaxis: {
      type: "value",
      min: minValue,
      max: maxValue,
      title: {
        text: "Temperature(°C)", // Set your Y-axis title here
        style: {
          fontFamily: "Montserrat, sans-serif", // Set font family for Y-axis title
          fontSize: "1rem", // Set font size for Y-axis title
          fontWeight: 700,
        },
      },
      labels: {
        formatter: function (value) {
          if (value !== null && typeof value !== "undefined" && !isNaN(value)) {
            return value.toFixed(2); // Format y-axis labels to 2 decimal places
          } else {
            return ""; // Return an empty string for undefined or NaN values
          }
        },
        style: {
          fontFamily: "Montserrat, sans-serif",
          fontSize: "14px",
          fontWeight: 500,
        },
      },
    },
    xaxis: {
      type: "numeric", // Use numeric type for hours
      // title: {
      //   text: "Hour", // Set your X-axis title here
      //   style: {
      //     fontFamily: "Montserrat", // Set font family for X-axis title
      //     fontSize: "14px", // Set font size for X-axis title
      //     fontWeight: 500,
      //   },
      // },

      labels: {
        formatter: function (value) {
          return value; // Append 'h' to represent hours
        },
        style: {
          fontFamily: "Montserrat", // Set font family for X-axis title
          fontSize: "14px", // Set font size for X-axis title
          fontWeight: 500,
        },
      },
    },
    tooltip: {
     x: {
      formatter: function (value) {
        return `${value}h`; // Show 'h' in the tooltip
      },
    },
      cssClass: "custom-tooltip",
      style: {
        fontFamily: "Montserrat, sans-serif", // Set font family for tooltip
        fontSize: "14px", // Set font size for tooltip
      },
    },
  });
  const [isChartDataAvailableTwo, setIsChartDataAvailableTwo] = useState(true);
  const [busbarParametersLastValue, setBusbarParametersLastValue] = useState(
    {}
  );
  const [busbarParametersTable, setBusbarParametersTable] = useState([]);

  const [heatmapDates, setHeatmapDates] = useState([]);
  const [heatmapValues, setHeatmapValues] = useState([]);
  const [heatmapData, setHeatmapData] = useState([]);
  const emptyChartData = {
    options: {
      xaxis: {
        title: "Datetime",
        tickmode: "array",
        zeroline: false,
        style: {
          fontFamily: "Montserrat, sans-serif",
        },
        tickvals: [],
      },
      yaxis: {
        style: {
          fontFamily: "Montserrat, sans-serif",
        },
        automargin: true,
      },
      dragmode: false,
      hovermode: false,
      showlegend: false,
      autosize: true,
      responsive: true,
    },
    series: [
      {
        x: [{}, {}, {}, {}, {}, {}],
        showscale: false,
        y: heatmapValues.slice().reverse(),
      },
    ],
  };
  useEffect(() => {
    if (minValue !== null && maxValue !== null) {
      setChartOption((prevOptions) => ({
        ...prevOptions,
        yaxis: {
          ...prevOptions.yaxis,
          min: minValue,
          max: maxValue,
        },
      }));
    } else if (minValue !== null) {
      setChartOption((prevOptions) => ({
        ...prevOptions,
        yaxis: {
          ...prevOptions.yaxis,
          min: minValue,
          max: undefined, // Keep max undefined if only minValue is available
        },
      }));
    } else {
      setChartOption((prevOptions) => ({
        ...prevOptions,
        yaxis: {
          ...prevOptions.yaxis,
          min: undefined,
          max: undefined, // Reset both if minValue is undefined
        },
      }));
    }
  }, [minValue, maxValue]);
  function formatDate(date) {
    // Subtract 5 hours and 30 minutes
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  const BusbarformatDate = (dateString) => {
    if (isDateString(dateString)) {
      const [year, month, day] = dateString.split("-");
      return `${day}-${month}-${year}`;
    }
    return dateString;
  };

  const series = [
    {
      name: "Loading (%)",
      data: [
        { x: "TX1", y: [2.3, 3.8, 4, 3.2, 4.8] },
        { x: "TX2", y: [3.9, 4.6, 4.9, 5.3, 6] },
        { x: "TX3", y: [5.9, 7.225, 7.5, 7.9, 8.9] },
        { x: "TX4", y: [2.3, 3.8, 4, 3.2, 4.8] },
        { x: "TX5", y: [3.9, 4.6, 4.9, 5.3, 6] },
        { x: "TX6", y: [5.9, 7.225, 7.5, 7.9, 8.9] },
      ],
    },
    {
      name: "Winding (°C)",
      data: [
        { x: "TX1", y: [2.4, 3.9, 4.1, 3.3, 4.9] },
        { x: "TX2", y: [4, 4.7, 5, 5.4, 7] },
        { x: "TX3", y: [6, 7.3, 7.6, 8, 9] },
        { x: "TX4", y: [2.4, 3.9, 4.1, 3.3, 4.9] },
        { x: "TX5", y: [4, 4.7, 5, 5.4, 7] },
        { x: "TX6", y: [6, 7.3, 7.6, 8, 9] },
      ],
    },
  ];
  
  const options = {
    chart: {
      type: "boxPlot",
    },
    legend: {
      markers: {
        fillColors: ["#1C75BD", "#8EC5EC"],
      },
      position: "top", // Place the legend on top
    },
    xaxis: {
      type: "category",
      categories: ["TX1", " TX2", " TX3", " TX4", " TX5", "TX6"],
      labels: {
        show: true,
        style: {
          fontWeight: "900px", // Bold labels
        },
      },
      style:{fontWeight:600}
    },
    yaxis: [
      {
        title: {
          text: "Loading (%)",
        },
        opposite: false, // Left side
      },
      {
        title: {
          text: "Winding Temperature (°C)",
        },
        opposite: true, // Right side
      },
    ],
    title: {
      text: "",
    },
    plotOptions: {
      boxPlot: {
        colors: {
          upper: undefined, // Not used because we will set colors per series
          lower: undefined,
        },
        stroke: {
          width: 0, // Removes the border between boxes
        },
      },
    },
    fill: {
      colors: ["#1C75BD", "#8EC5EC"], // Set specific colors for each series
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const seriesName = w.globals.seriesNames[seriesIndex];
        const data =
          w.globals.initialSeries[seriesIndex].data[dataPointIndex].y;
        const [close, low, average, high, open] = data; // Using array destructuring
  
        return `
          <div style="padding: 10px; border: 1px solid #ddd; background: white;">
            <strong>${seriesName}</strong><br/>
            <div>Open: ${open}</div>
            <div>High: ${high}</div>
            <div>Average: ${average}</div>
            <div>Low: ${low}</div>
            <div>Close: ${close}</div>
          </div>
        `;
      },
    },
  };
  
  

  // Function to show flash message with a delay
  const showFlashMessage = (message, variant = "danger") => {
    const newAlert = {
      message: message,
      variant: variant,
      id: new Date().getTime(),
    };

    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) => prevAlerts.slice(1));
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [alerts]);

  const [chartOptions, setChartOptions] = useState({
    options: {
      chart: {
        type: "bar",
        height: 400,
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        type: "category", // Specify x-axis type as category
        categories: [], // Empty initially until data is fetched
      },
    },
    series: [], // Initialize series array
  });

  useEffect(() => {
    const carousel = document.querySelector("#carouselExample");

    if (carousel) {
      // Listen to the slide event to update activeTab when the slide changes
      const handleSlide = (e) => {
        setActiveTab(e.to); // e.to gives the index of the next active slide
      };

      carousel.addEventListener("slide.bs.carousel", handleSlide);

      // Cleanup the event listener when the component unmounts
      return () => carousel.removeEventListener("slide.bs.carousel", handleSlide);
    }
  }, []);

  useEffect(() => {
    Heatmap()
    if (token !== null && token !== undefined && token !== "") {
      getLocation();
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageLocation();
    }
  }, [locationData]);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== "") {
      localStorageAsset();
    }
  }, [assetData]);

  useEffect(() => {
    if (onload === true) {
      try {
        const data = {
          // fromDate: fromDate,
          // toDate: toDate,
          // location: selectedLocation,
          // site: selectedAsset,
          location: onloadLoc,
          site: onloadAsset,
        };

        handleSubmitData(data);
      } catch (error) {}
    }
  }, [onload]);

  // getting Token
  useEffect(() => {
    const tkData = Cookies.get("tkData");

    if (tkData) {
      const tokenData = JSON.parse(tkData);
      setToken(tokenData.token);
      setUserLogId(tokenData.userLogId);
    }
  }, []);

  const localStorageLocation = () => {
    if (
      localStorage.getItem("location") !== undefined &&
      localStorage.getItem("location") !== "undefined" &&
      localStorage.getItem("location") !== "null" &&
      localStorage.getItem("location") !== null
    ) {
      let location = localStorage.getItem("location");
      location = JSON.parse(location);
      const flag = locationData.some((item) => item.value === location.value);
      if (flag) {
        setSelectedLocation(location);
        setOnloadLoc(location);
        getAsset(location.value);
        // setHeader({ location: location.label });
        return;
      }
    } else {
      localStorage.removeItem("asset");
      if (locationData.length > 0) {
        const new_location = locationData[0];
        // setSelectedLocation(new_location);
        setOnloadLoc(new_location);
        getAsset(new_location.value);
        // setHeader({ location: new_location.label });
        // alert("done")
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const localStorageAsset = () => {
    if (
      localStorage.getItem("asset") !== undefined &&
      localStorage.getItem("asset") !== "undefined" &&
      localStorage.getItem("asset") !== "null" &&
      localStorage.getItem("asset") !== null
    ) {
      let asset = localStorage.getItem("asset");
      asset = JSON.parse(asset);
      const flag = assetData.some((item) => item.value === asset.value);
      if (flag) {
        setSelectedAsset(asset);
        setOnloadAsset(asset);
        // setHeader({ location: selectedLocation.label, asset: asset.label });
        setOnLoad(true);
      }
    } else {
      if (assetData.length > 0) {
        const new_asset = assetData[0];
        setOnloadAsset(new_asset);
        // setSelectedAsset(new_asset);
        // setHeader({ location: selectedLocation.label, asset: new_asset.label });
        setOnLoad(true);
      } else {
        showFlashMessage("Please select Location and Asset to display data.");
      }
    }
  };

  const changeLocation = (props) => {
    // localStorage.setItem("location", JSON.stringify(props));
    // setSelectedLocation(props);
    setOnloadLoc(props);
    if (token !== null && token !== undefined && token !== "") {
      getAsset(props.value);
    }
  };

  const changeAsset = (props) => {
    setOnloadAsset(props);
    // localStorage.setItem("asset", JSON.stringify(props));
    // setSelectedAsset(props);
    if (token !== null && token !== undefined && token !== "") {
      // getAsset(props);
    }
  };

  const getLocation = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_locations`,
            {
              access_token: token,
              user_log_id: userLogId,
              iot_sol: "Other",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data
              .map((item) => ({
                value: item._id,
                label: item.name,
              }))
              .sort((a, b) => a.label.localeCompare(b.label)); // Sort data by name

            if (response.data.data.length === 0) {
              // showFlashMessage("Locations are Not Found");
            }
            setLocationData(data);
            success = true;
          } else {
            // showFlashMessage("Locations are Not Found");
          }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      showFlashMessage("Locations are Not Found");
    }
  };

  const getAsset = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/filter/get_assets`,
            {
              access_token: token,
              location_id: props,
              user_log_id: userLogId,
              iot_sol: "Other",
            }
          );

          if (response.status === 200 && response.data.success === true) {
            const data = response.data.data.map((item) => ({
              value: item._id,
              label: item.name,
            }));

            setAssetData(data);
            success = true;
            if (response.data.data.length === 0) {
              setAssetData([]);
              // showFlashMessage("Assets are Not Found");
            }
          } else {
            setAssetData([]);
            // showFlashMessage("Assets are Not Found");
          }
          // else  if (response.status === 200 && response.data.success === false) {
          //   showFlashMessage(response.data.msg);
          // }
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
    }
    if (!success) {
      setAssetData([]);
      showFlashMessage("Assets are Not Found");
    }
  };

  const [csvData, setCsvData] = useState([]);
  const [tableLastDate, setTableLastDate] = useState();

  const getChart = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      try {
        if (token) {
          const response = await axios.post(
            `${process.env.REACT_APP_VARIABLE}/iotrms/pH/pH_trend`,
            props,
            {
              headers: {
                Accept: "application/json",
                "Content-type": "application/json",
              },
            }
          );
          const chartData = response.data.data;
          if (chartData.length === 0) {
          }
          setCsvData(chartData);
          success = true;
          setTableLastDate(response.data.last_date);
          setIsChartDataAvailable(true);
          setChartOptions(chartData);
          setIsLoading(false);
        }
      } catch (error) {
        setIsChartDataAvailable(false);
        console.error("Error fetching chart data:", error);
        setIsLoading(false);
      }
      attempts++;
    }
    if (!success) {
      setIsLoading(false);
      setIsChartDataAvailable(false);
      setChartOptions();
      showFlashMessage(
        "Data Not Available For pH Status (Last 2 Hours)",
        "danger"
      );
    }
  };

  const breadcrum_date_converter = (date) => {
    let dateString = date;
    let [datePart, timePart] = dateString.split(" ");
    let [year, month, day] = datePart.split("-");
    let [hours, minutes] = timePart.split(":");
    let myDate = day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    return myDate;
  };

  const truncateToOneDigits = (value) => {
    if (value !== undefined) {
      const truncatedValue = Math.trunc(value * 10) / 10;
      return isNaN(truncatedValue) ? "-" : truncatedValue;
    }
    return "";
  };

  const Heatmap = async (props) => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      const data = props;
      // setIsLoading(true);
     
      try {
        const response =
        {
          "data": {
              "success": true,
              "data": [
                  {
                      "heatmap_values": [
                          {
                              "name": "Tx 1",
                              "values": [
                                  69,
                                  68.8,
                                  66.2,
                                  64.5,
                                  63.3,
                                  62.5,
                                  62.6,
                                  63.8,
                                  10.2,
                                  67.5,
                                  68.6,
                                  15,
                                  68.8
                              ],
                              "min": "0",
                              "max": "20"
                          },
                          {
                              "name": "tx 2",
                              "values": [
                                  60.3,
                                  60.1,
                                  57.6,
                                  55.1,
                                  53.6,
                                  52.6,
                                  52.6,
                                  55,
                                  57.8,
                                  60.3,
                                  61.3,
                                  61.6,
                                  61.7
                              ],
                              "min": "0",
                              "max": "80"
                          },
                          {
                              "name": "tx 3",
                              "values": [
                                  63.1,
                                  62.8,
                                  59.8,
                                  57.8,
                                  56.5,
                                  56.5,
                                  56.5,
                                  58.5,
                                  60.6,
                                  62,
                                  62.3,
                                  62.1,
                                  62.3
                              ],
                              "min": "0",
                              "max": "80"
                          },
                          {
                              "name": "tx 4",
                              "values": [
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-"
                              ],
                              "min": "-",
                              "max": "-"
                          },
                          {
                              "name": "Tx 5",
                              "values": [
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-"
                              ],
                              "min": "-",
                              "max": "-"
                          },
                          {
                              "name": "Tx 6",
                              "values": [
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-",
                                  "-"
                              ],
                              "min": "-",
                              "max": "-"
                          }
                      ],
                      "heatmap_dates": {
                          "created_at": [
                              12,
                              11,
                              10,
                              9,
                              8,
                              7,
                              6,
                              5,
                              4,
                              3,
                              2,
                              1,
                              0
                          ]
                      }
                  }
              ],
              "start_date": "21-11-2024 00:00",
              "end_date": "21-11-2024 23:59"
          },
          "status": 200,
          "statusText": "",
          "headers": {
              "content-length": "852",
              "content-type": "application/json; charset=utf-8"
          },
          "config": {
              "transitional": {
                  "silentJSONParsing": true,
                  "forcedJSONParsing": true,
                  "clarifyTimeoutError": false
              },
              "adapter": [
                  "xhr",
                  "http",
                  "fetch"
              ],
              "transformRequest": [
                  null
              ],
              "transformResponse": [
                  null
              ],
              "timeout": 0,
              "xsrfCookieName": "XSRF-TOKEN",
              "xsrfHeaderName": "X-XSRF-TOKEN",
              "maxContentLength": -1,
              "maxBodyLength": -1,
              "env": {},
              "headers": {
                  "Accept": "application/json, text/plain, */*",
                  "Content-Type": "application/json"
              },
              "method": "post",
              "url": "https://rms-node.motware.me/iotrms/busbar/busbar_parameters_heatmap",
              "data": "{\"access_token\":\"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50X2lkIjoiNjBlOTQxNGM5NGFjNzUwYzRhYjU2YWQ1IiwidXNlcl9pZCI6IjY2NmQ3MWMyNzAwN2NkMDAxNjU1MzY1NSIsInVzZXJuYW1lIjoiQWRtaW4gVXNlciIsImlhdCI6MTczMjY5ODI1NCwiZXhwIjoxNzMzMDQzODU0fQ.X_4zr3ADDnrMHSunlvD_9zVPtEHeAzDhgB2VXO2addA\",\"user_log_id\":\"6746e0726e5f2fbd2fe30e6d\",\"location_id\":\"60ed1fb494ac75669275e702\",\"assset_id\":\"664758aa7007cd0012bfc936\",\"iot_sol\":\"Transformer\"}"
          },
          "request": {}
      }
        //  await axios.post(
        //   `${process.env.REACT_APP_VARIABLE}/iotrms/busbar/busbar_parameters_heatmap`,
        //   data
        // );
        // if (response.data.data.length === 0) {
        //   setChartData();
        //   setIsChartDataAvailableTwo(false);
        //   // showFlashMessage(
        //   //   "Data Not Available for Maximum value recorded",
        //   //   "danger"
        //   // );
        //   setIsLoading(false);
        //   return;
        // }
        if (
          response.status === 200 &&
          response.data &&
          response.data.success === true
        ) {
          const data = response.data;
         

          const heatmapData = data.data;
          setHeatmapData(heatmapData);
          success = true;

          const formattedDates = heatmapData[0].heatmap_dates.created_at.map(
            (date) => BusbarformatDate(date)
          );

          setHeatmapDates(formattedDates);
          setHeatmapValues(
            heatmapData[0].heatmap_values.map((val) => val.name)
          );

          if (heatmapData[0].heatmap_values && heatmapData[0].heatmap_dates) {
            const { heatmap_values, heatmap_dates } = heatmapData[0];
            const reversedValues = heatmap_values.reverse();
            const xValues = formattedDates;
            const yValues = heatmap_values.map((val) => val.name);
            const zValues = heatmap_values.map((val) =>
              val.values.map((value) => {
                if (value === "-") {
                  return 0.5; //value which are dash show 0.5
                } else if (value < val.min || value > val.max) {
                  return 0; // Value outside range, use 0 for red color
                } else {
                  return 1; // Value within range, use 1 for green color
                }
              })
            );


            const title = heatmap_dates.created_at.some(
              (timestamp) => typeof timestamp === "string"
            )
              ? "Datetime"
              : "Time";
            // const title = "name"
            const zValue = heatmap_values.map((val) =>
              val.values.map((value) => truncateToOneDigits(value))
            );
            const data = [
              {
                showscale: false, // Hide the color scale
                x: xValues,
                y: yValues,
                z: zValues,
                xgap: 1,
                ygap: 1,
                customdata: zValue, // Flatten zValues for custom data
                hovertemplate: `<b>%{y}</b><br>${isDateString(xValues[0]) ? "Datetime" : "Time"
                  }: %{x}<br>Value: %{customdata}<extra></extra>`,
                type: "heatmap",
                colorscale: [
                  [0, "#FF6873"], // Use red for values outside the range
                  [0.5, "#C1DEB8"], // Use light green for null values
                  [1, "#C1DEB8"], // Use green for values within the range
                ],
                zmin: 0, // Min value for the color scale
                zmax: 1, // Max value for the color scale
              },
            ];

            const layout = {
              modebardisplay: false,
              margin: {
                l: 10, 
                r: 10, 
                b: 0,
                t: 35,
              },
              xaxis: {
                automargin: true,
                title: title,
                tickmode: "array",
                tickvals: xValues,
                ticktext: xValues,
                zeroline: false,
                style: {
                  fontFamily: "Montserrat, sans-serif",
                },
              },
              yaxis: {
                style: {
                  fontFamily: "Montserrat, sans-serif",
                },

                // fixedrange: true,
                showticklabels: true, // Show tick labels
                automargin: true, // Automatically adjust margins
              },
              dragmode: false, // Disable panning
              hovermode: "closest", // Display hover info for closest point
              showlegend: false, // Hide legend
              autosize: true,
              responsive: true,
            };

            setChartData({
              series: data,
              options: layout,
            });
            setIsChartDataAvailableTwo(true);
            // if (response.data.start_date && response.data.end_date) {
            //   setNewStartDate(response.data.start_date);
            //   setNewEndDate(response.data.end_date);
            // }
          } else {
            setIsChartDataAvailableTwo(false);
            // showFlashMessage(
            //   "Data Not Available for Maximum value recorded",
            //   "danger"
            // );
            setChartData({ series: [], options: {} });
          }
        } else {
          setIsChartDataAvailableTwo(false);
          setChartData({ series: [], options: {} });
          // showFlashMessage(
          //   "Data Not Available for Maximum value recorded",
          //   "danger"
          // );
        }
      } catch (error) {
        setIsChartDataAvailableTwo(false);
        setChartData({ series: [], options: {} });
        console.error("Error fetching heatmap data:", error);
      }
      attempts++;
      // setIsLoading(false);
    }
    if (!success) {
      setChartData();
      setIsChartDataAvailableTwo(false);
      showFlashMessage(
        "Data Not Available for Maximum value recorded",
        "danger"
      );
      // setIsLoading(false);
    }
  };
  const isDateString = (str) => {
    // Check if a string matches a date pattern
    return /\d{4}-\d{2}-\d{2}/.test(str);
  };
  const excelIcon = {};

  if (heatmapDates.length > 0 && heatmapValues.length > 0) {
    const generateCSVContent = () => {
      // Create header row for CSV
      const title = heatmapData[0].heatmap_dates.created_at.some(
        (timestamp) =>
          typeof timestamp === "string" || typeof timestamp === "number"
      )
        ? "Date Time, "
        : "Time, ";
      let csvContent = title + heatmapValues.join(",") + "\n";

      // Create rows for each date with corresponding values
      for (let i = 0; i < heatmapDates.length; i++) {
        const reversedDates = heatmapDates.slice().reverse();

        const date = reversedDates[i];
        const values = heatmapData[0].heatmap_values.map((val) => {
          const value = val.values.slice().reverse()[i];
          const truncatedValue =
            typeof value === "number" || typeof value === "string"
              ? truncateToOneDigits(value)
              : value;
          return truncatedValue ? `"${truncatedValue}"` : truncatedValue;
        });

        csvContent += `${date},${values.reverse().join(",")}\n`;
      }

      return csvContent;
    };

    excelIcon.name = "excel-icon";
    excelIcon.icon = {
      width: "100%",
      height: 500,
      path: "M48 448V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z",
    };

    excelIcon.click = function (gd) {
      const csvContent = generateCSVContent();
      const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Heatmap.csv");
      document.body.appendChild(link);
      link.click();
    };

    excelIcon.title = "Export to Excel";
  }

  //chart
  const getGraphData = async () => {
    if (!token) {
      // Return early if token is not available
      return;
    }

    let attempts = 0;
    const maxAttempts = 3;
    let success = false;

    while (attempts < maxAttempts && !success) {
      setIsLoading(true);
      // const data = props;
      try {
        if (token !== null && token !== undefined && token !== "") {
          const response = {
            data: {
              success: true,
              data: [
                {
                  name: "lv ry voltage",
                  data: [
                    [1726876740000, 2],
                    [1726876740000, 3],
                    [1726876740000, 4],
                    [1726790400000, 5],
                  ],
                },
              ],
              start_date: "20-09-2024 00:00",
              end_date: "20-09-2024 23:59",
            },
            status: 200,
            statusText: "",
            headers: {
              "content-type": "application/json; charset=utf-8",
            },
            config: {
              transitional: {
                silentJSONParsing: true,
                forcedJSONParsing: true,
                clarifyTimeoutError: false,
              },
              adapter: ["xhr", "http", "fetch"],
              transformRequest: [null],
              transformResponse: [null],
              timeout: 0,
              xsrfCookieName: "XSRF-TOKEN",
              xsrfHeaderName: "X-XSRF-TOKEN",
              maxContentLength: -1,
              maxBodyLength: -1,
              env: {},
              headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
              },
              method: "post",
              url: "https://rms-node.motware.me/iotrms/trend/graph_trend",
              data: '{"access_token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50X2lkIjoiNjE3NjIzMWM5NGFjNzUwYzRhYjU2YzUwIiwidXNlcl9pZCI6IjY2MjlmNGNhNzAwN2NkMDAxMmJmYzNjMCIsInVzZXJuYW1lIjoiQmhhdmluIEJoYWdhdCIsImlhdCI6MTczMjY4NDU4MSwiZXhwIjoxNzMzMDMwMTgxfQ.LQcNg0eezQMEl6f4e4aQ8T9Bzz0PKa-dnEBhTLNpII0","user_log_id":"6746ab156e5f2fbd2fe309e6","location_id":"6179567d94ac75180daeb58e","assset_id":"617bbe4194ac75180969ca7c","sensor_parameters":["lv_ry_voltage"]}',
            },
            request: {},
          };
          //  await axios.post(`${process.env.REACT_APP_VARIABLE}/iotrms/trend/graph_trend`, data);
          // if (response.data.data.length === 0) {
          //   let result = Array.isArray(selectedParameter) ? selectedParameter.map((res) => ({ "name": res.label, "data": [] })) : [];
          //   setChartSeries(result)
          //   setIsLoading(false);
          //   return;
          // }
          if (response.status === 200 && response.data.success === true) {
            if (response.data.data.length > 0) {
              let currentMinValue = null;
              let currentMaxValue = null;
              const chartData = response.data.data.map((map) => {
                return {
                  data: map.data
                    .filter(([timestamp, value]) => value !== null)
                    .map(([timestamp, value]) => {
                      const truncatedValue = value;

                      //adjust minValue
                      if (typeof truncatedValue === "number") {
                        const adjustedValue = truncatedValue - 0.1;

                        // Set minValue if it's the first match or the smallest found
                        if (
                          currentMinValue === null ||
                          adjustedValue < currentMinValue
                        ) {
                          currentMinValue = adjustedValue;
                        }

                        // Update the maxValue if necessary
                        if (
                          currentMaxValue === null ||
                          value > currentMaxValue
                        ) {
                          currentMaxValue = value;
                        }
                      }
                      return [timestamp, truncatedValue];
                    }),
                };
              });

              // If a valid min value was found, update the state
              if (currentMinValue !== null) {
                setMinValue(currentMinValue);
              }

              // If a valid max value was found, update the state by adding 0.1 after decimal
              if (currentMaxValue !== null) {
                setMaxValue(currentMaxValue + 0.1);
              }
              console.log("response.data.data", response.data.data);

              setChartSeries(response.data.data);
              success = true;
              // setHeader({ location: selectedLocation.label, title: selectedAsset.label })
              // showFlashMessage("Data F");
              setIsLoading(false);
            } else {
              setIsLoading(false);
              // showFlashMessage("Data Not Available");
              // let result = Array.isArray(selectedParameter) ? selectedParameter.map((res) => ({ "name": res.label, "data": [] })) : [];
              // setChartSeries(result)
            }
          } else {
            // let result = Array.isArray(selectedParameter) ? selectedParameter.map((res) => ({ "name": res.label, "data": [] })) : [];
            // setChartSeries(result)
            setIsLoading(false);
            // showFlashMessage("Data Not Available");
          }
          // setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
      attempts++;
      setIsLoading(false);
    }
    if (!success) {
      showFlashMessage("Data Not Available", "danger");
      // let result = Array.isArray(selectedParameter) ? selectedParameter.map((res) => ({ "name": res.label, "data": [] })) : [];
      // setChartSeries(result)
      setIsLoading(false);
    }
  };
  console.log("chartSeries", chartSeries);
  console.log("options", chartOptions);
  useEffect(() => {
    getGraphData();

    if (csvData && csvData.length > 0) {
      setChartOptions(csvData);
    }
  }, []);
  const handleSubmitData = (props) => {
    const { location, site } = props;

    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    setOnloadLoc(updatedLocation);
    setOnloadAsset(updatedSite);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        // location_id: location.value,
        assset_id: site.value,
      };

      getChart(data);
    }
  };

  const handleSubmitDataOnClick = (props) => {
    const { location, site } = props;

    localStorage.setItem("location", JSON.stringify(onloadLoc));
    localStorage.setItem("asset", JSON.stringify(onloadAsset));

    const storedLocation = JSON.parse(localStorage.getItem("location"));
    const updatedLocation = storedLocation || location;

    const storedSite = JSON.parse(localStorage.getItem("asset"));
    const updatedSite = storedSite || site;

    setSelectedLocation(updatedLocation);
    setSelectedAsset(updatedSite);

    setOnloadLoc(updatedLocation);
    setOnloadAsset(updatedSite);

    if (token !== null && token !== undefined && token !== "") {
      const data = {
        access_token: token,
        user_log_id: userLogId,
        // location_id: location.value,
        assset_id: onloadAsset.value,
      };
      getChart(data);
    }
  };

  const chartDatas = {
    "chartData": [
        {
            "consumption": 50,
            "benchmark": 45,
            "created_at": "TX 1"
        },
        {
          "consumption": 30,
          "benchmark": 50,
          "created_at": "TX 2"
      },
      {
        "consumption": 50,
        "benchmark": 45,
        "created_at": "TX 3"
    },
    {
      "consumption": 35,
      "benchmark": 25,
      "created_at": "TX 4"
  },
  {
    "consumption": 40,
    "benchmark": 50,
    "created_at": "TX 5"
}

    ]
}
  const chartDataArray = chartDatas?.chartData || [];

  const formatDates = (dateString) => {
    const dateParts = dateString.split('-');
    
    // Expecting dateString in format YYYY-MM-DD
    const [year, month, day] = dateParts;
  
    // Format the date in "DD-MM-YYYY"
    return `${day}-${month}-${year}`;
  };

  const labels = chartDataArray.map((item) => (item.created_at));

  const consumptionValues = chartDataArray.map((item) =>
    item.consumption.toFixed(1)
  );

  const benchmarkValues = chartDataArray.map((item) =>
    item.benchmark.toFixed(1)
  );

  const chartDataMain = {
    options: {
      chart: {
        toolbar: {
          export: {
            csv: {
              filename: "Energy Transferred (kWh)",
              headerCategory: 'Date',
              categoryFormatter: (value) => value,
            },
            png: {
              filename: "Energy Transferred (kWh)",
            },
            svg: {
              filename: "Energy Transferred (kWh)",
            },
          },
        },
        animations: {
          enabled: false,
        },
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: [ "#7BBFE9","#1B75BB"],
      },
      xaxis: {
        categories: labels,
        title: {
          text: "Transformer",
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font for x-axis title
            fontSize: '14px',          // Set the font size
            fontWeight: '500',         // Adjust font weight
          },
        },
        labels: {
          show: labels.length > 0,
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font for x-axis labels
            fontSize: '14px',
            fontWeight: '500',
          },
          formatter: (value) => value,
        },
      },
      yaxis: {
        title: {
          text: "Value",
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font for y-axis title
            fontSize: '14px',
            fontWeight: '500',
            color: '#000000',
          },
          
        },
      },
      fill: {
        opacity: 1,
        colors: [ "#7BBFE9","#1B75BB"],
        type: ["solid", "solid"],
        markers: {
          colors: [ "#7BBFE9","#1B75BB"],
        },
      },
  
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font for tooltip
            fontSize: '14px',
            fontWeight: '500',
          },
        },
      },
      legend: {
        position: "top",  // Legends at the top
        labels: {
          style: {
            fontFamily: "Montserrat",  // Apply Montserrat font for legend
            fontSize: '14px',
            fontWeight: '500',
          },
        },
        markers: {
          fillColors: [ "#7BBFE9","#1B75BB"],
        },
      },
      grid: {
        padding: {
          bottom: 20, // Padding to prevent cut-off
        },
      },
    },
    series: [
      {
        name: "Consumption",
        data: consumptionValues,
        style: {
          fontFamily: "Montserrat",  // Apply Montserrat font to series names
          fontSize: '14px',
          fontWeight: '500',
        },
        markers: {
         colors: ["#7BBFE9"],
        },
      },
      {
        name: "Benchmark",
        data: benchmarkValues,
        style: {
          fontFamily: "Montserrat",  // Apply Montserrat font to series names
          fontSize: '14px',
          fontWeight: '500',
          color: '#000000',
        },
        markers: {
          colors: ["#1B75BB"],
          
        },
      },
    ],
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
    const carousel = document.querySelector("#carouselExample");
    if (carousel) {
      const items = carousel.querySelectorAll(".carousel-item");
      items.forEach((item, idx) => {
        item.classList.toggle("active", idx === index); // Set the active class on the right item
      });
    }
  };

  const changeToggleButton = (checked) => {
    let toggleParamColorElement = document.getElementById("toggleParamColor");
    let spanElements = toggleParamColorElement.querySelectorAll("span");
    if (checked) {
      spanElements[0].style.color = "black";
      spanElements[1].style.color = "white";
    } else {
      spanElements[1].style.color = "black";
      spanElements[0].style.color = "white";
    }
  };

  return (
    <>
      <div className="wrapper">
        {alerts.map((alert, index) => (
          <Alert
            key={alert.id}
            id={`flash-message-${alert.id}`}
            variant={alert.variant === "success" ? "success" : "danger"}
            onClose={() =>
              setAlerts((prevAlerts) =>
                prevAlerts.filter((a) => a.id !== alert.id)
              )
            }
            dismissible
            style={{ width: "30%", marginBottom: `${index * 25 * 2}px` }}
          >
            {alert.message}
          </Alert>
        ))}

        {/* asset location */}
        {/* <div className="content-wrapper">
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="mdlContent card pr-card mb30">
                    <div className="breadcrumbHdr card-header">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="#">{selectedAsset.label}</a>
                        </li>
                        <li className="breadcrumb-item ">
                          <a href="#">{selectedLocation.label}</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a>{tableLastDate}</a>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div> */}

        <div className="content-wrapper">
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="mdlContent card pr-card mb30">
                    <div className="mdlContent card-body fleetWpr">
                      <div className="col-md-12 col-lg-12">
                        <div className="card pr-card">
                        <div className="topBtn inrCardBody">
                          {/* <div className="form-group switch float-left">
                            <label className="switch-light">
                              <input type="checkbox" name="isGroupSwitchOn" disabled/>
                              <span id="toggleParamColor">
                                <span style={{ color: "white" }}>
                                  Fleet View
                                </span>
                                <span>TX View</span>
                              </span>
                              <a className="btn btn-primary"></a>
                            </label>
                          </div> */}
                          <div
                  className="form-group switch float-left"
                  // onChange={getGroupName}
                >
                  <label className="switch-light">
                    <input
                    disabled
                      type="checkbox"
                      // checked={formik.values.isParam}
                      onChange={(e) => {
                        // formik.setFieldValue("isParam", e.target.checked);
                        // changeIsParam(e.target.checked);
                        changeToggleButton(e.target.checked);
                      }}
                      onBlur={() => {
                        // formik.handleBlur('site');
                        // formik.setFieldTouched("isParam", true);
                      }}
                      name="isGroupSwitchOn"
                    />
                    {/* <span>
                      <span>Parameter</span>
                      <span>Group</span>
                    </span> */}
                    <span id="toggleParamColor">
                      <span style={{ color: "white" }}>Fleet View</span>
                      <span>TX View</span>
                    </span>
                    <a className="btn btn-primary"></a>
                  </label>
                </div>
                          {/* <div className="float-right form-group">
                            <a href="#carouselExample" className="btn active">
                              Loading Utilization And Temp Stress
                            </a>
                            <a href="#carouselExample" className="btn">
                              Electrical Parameters Cockpit
                            </a>
                            <a href="#carouselExample" className="btn">
                              Temperature Cockpit
                            </a>
                            <a href="#carouselExample" className="btn">
                              Relays And Alerts List
                            </a>
                            <a href="#carouselExample" className="btn">
                              Energy Consumption Benchmark VS Actuals
                            </a>
                          </div> */}
                           <div className="float-right form-group">
            {["Loading Utilization And Temp Stress", "Electrical Parameters Cockpit", "Temperature Cockpit", "Relays and Alerts List", "Energy Consumption Benchmark VS Actuals"].map((label, index) => (
              <a
                key={index}
                href="#"
                className={`btn ${activeTab === index ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  handleTabClick(index);
                }}
              >
                {label}
              </a>
            ))}
          </div>
                        </div>
                        </div>
                      </div>
                      <div
            id="carouselExample"
            className="carousel slide w-100"
            data-bs-ride="carousel" // Disable automatic sliding
          >

                        {/* <div className="carousel-indicators">
                          <button
                            type="button"
                            data-bs-target="#carouselExample"
                            data-bs-slide-to="0"
                            className="active"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#carouselExample"
                            data-bs-slide-to="1"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#carouselExample"
                            data-bs-slide-to="2"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#carouselExample"
                            data-bs-slide-to="3"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#carouselExample"
                            data-bs-slide-to="4"
                          ></button>
                        </div> */}
                        <div className="carousel-indicators">
              {Array.from({ length: 5 }).map((_, index) => (
                <button
                  key={index}
                  type="button"
                  data-bs-target="#carouselExample"
                  data-bs-slide-to={index}
                  className={activeTab === index ? "active" : ""}
                  onClick={() => handleTabClick(index)}
                ></button>
              ))}
            </div>
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            {/* <div className="col-md-12 col-lg-12">
                              <div className="row">
                                <div className="col-md-6 col-lg-6">
                                  <div className="info-box">
                                    <span className="info-box-icon bg-greenPin elevation-1"></span>
                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        Active
                                      </span>
                                    </div>
                                    <div className="info-box-number">
                                      <span className="info-box-text">1</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                  <div className="info-box">
                                    <span className="info-box-icon bg-grayPin elevation-1"></span>
                                    <div className="info-box-content">
                                      <span className="info-box-text">
                                        Inactive
                                      </span>
                                    </div>
                                    <div className="info-box-number">
                                      <span className="info-box-text">0</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            <div className="col-md-12 col-lg-12">
                              <div className="card pr-card mb30 tableCard">
                                <div className="inrCardHdr">
                                  <h5>Latest Alerts</h5>
                                </div>
                                <div className="inrCardBody">
                                  <div className="table-responsive">
                                    <table
                                      className="table table-hover dtr-inline"
                                      style={{ width: "100%" }}
                                    >
                                      <thead>
                                        <tr>
                                          <th>
                                            Date Time
                                          </th>
                                          <th>
                                            Location
                                          </th>
                                          <th>Asset</th>
                                          <th>
                                            Parameter
                                          </th>
                                          <th>Value</th>
                                          <th>Alert</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td><a href="#">24 Aug 12:10 PM</a></td>
                                          <td><a href="#">Raichur 1</a></td>
                                          <td><a href="#">LTSS 3 TR 2 (2MVA)</a></td>
                                          <td><a href="#">Y Line Current A</a></td>
                                          <td><a href="#">140.92</a></td>
                                          <td><a href="#">
                                            Attention! Current limit crossed</a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><a href="#">22 Aug 08:53 AM</a></td>
                                          <td><a href="#">Nashik</a></td>
                                          <td><a href="#">LTSS 3 TR 2 (2MVA)</a></td>
                                          <td><a href="#">B Line Current A</a></td>
                                          <td><a href="#">141.41</a></td>
                                          <td><a href="#">
                                            Attention! Current limit crossed</a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><a href="#">08 Aug 09:17 AM</a></td>
                                          <td><a href="#">GANGTOK</a></td>
                                          <td><a href="#">LTSS 3 TR 2 (2MVA)</a></td>
                                          <td><a href="#">B Line Current A</a></td>
                                          <td><a href="#">140.57</a></td>
                                          <td><a href="#">
                                            Attention! Current limit crossed</a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><a href="#">02 Aug 08:53 PM</a></td>
                                          <td><a href="#">Nashik</a></td>
                                          <td><a href="#">LTSS 3 TR 2 (2MVA)</a></td>
                                          <td><a href="#">B Line Current A</a></td>
                                          <td><a href="#">141.01</a></td>
                                          <td><a href="#">
                                            Attention! Current limit crossed</a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><a href="#">01 Aug 09:17 PM</a></td>
                                          <td><a href="#">GANGTOK</a></td>
                                          <td><a href="#">LTSS 3 TR 2 (2MVA)</a></td>
                                          <td><a href="#">B Line Current A</a></td>
                                          <td><a href="#">140.57</a></td>
                                          <td><a href="#">
                                            Attention! Current limit crossed</a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-12">
                              <div className="card pr-card mb30">
                                <div className="inrCardHdr">
                                  <h5>
                                    Loading Utilization And Temperature Stress
                                  </h5>
                                </div>
                                <div className="card-body">
                                  <div id="myDiv">
                                    <ReactApexChart
                                      options={options}
                                      series={series}
                                      type="boxPlot"
                                      height={390}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div className="col-md-12 col-lg-12">
                              <div className="card pr-card mb30">
                                <div className="inrCardHdr">
                                  <h5>Electrical Parameters Cockpit</h5>
                                </div>
                                <div className="inrCardBody">
                                {/* {isChartDataAvailableTwo ? (
                      <Plot
                        theme="streamlit"
                        data={chartData.series}
                        layout={chartData.options}
                        config={{
                          displayModeBar: true,
                          displaylogo: false,
                          responsive: true,
                          toImageButtonOptions: {
                            filename: "Busbar",
                          },
                          modeBarButtonsToAdd: [excelIcon],

                          modeBarButtonsToRemove: [
                            "select2d",
                            "lasso2d",
                            "autoscale",
                            "zoom2d",
                            "pan2d",
                            "zoomIn2d",
                            "zoomOut2d",
                            "autoScale2d",
                            "resetScale2d",
                            "hoverClosestCartesian",
                            "hoverCompareCartesian",
                          ],
                        }}
                        style={{ width: "100%", height: 650 }} // Adjust style based on your requirements
                      />
                    ) : (
                      <>
                        <Plot
                          data={emptyChartData.series}
                          layout={emptyChartData.options}
                          config={{
                            displayModeBar: true,
                            displaylogo: false,
                            responsive: true,
                            toImageButtonOptions: {
                              filename: "Busbar",
                            },
                            // modeBarButtonsToAdd: [excelIcon],

                            modeBarButtonsToRemove: [
                              "toImage",
                              "select2d",
                              "lasso2d",
                              "autoscale",
                              "zoom2d",
                              "pan2d",
                              "zoomIn2d",
                              "zoomOut2d",
                              "autoScale2d",
                              "resetScale2d",
                              "hoverClosestCartesian",
                              "hoverCompareCartesian",
                            ],
                          }}
                          type="heatmap"
                          style={{ width: "100%", height: 650 }}
                        />
                      </>
                    )} */}
                            <table className="table dtr-inline eleParaTbl" style={{width:"100%"}}>
                              <thead>
                                <tr>
                                  <th>Transformer Name</th>
                                  <th className="text-center td_width">Current Unbalance (%)</th>
                                  <th className="text-center td_width">Over/Under Voltage (%)</th>
                                  <th className="text-center td_width">Power Factor</th>
                                  <th className="text-center td_width">OLTC Count</th>
                                  <th className="text-center td_width">Total Harmonic Distortion (%)</th>
                                  <th className="text-center td_width">Hotspot (Deg C)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Tx 1</th>
                                  <td className="bg-greenPin"><a href="#">4.3 %</a></td>
                                  <td className="bg-greenPin"><a href="#">-1.5 %</a></td>
                                  <td className="bg-greenPin"><a href="#">0.90</a></td>
                                  <td className="bg-greenPin"><a href="#">23</a></td>
                                  <td className="bg-greenPin"><a href="#">2.0 %</a></td>
                                  <td className="bg-greenPin"><a href="#">47</a></td>
                                </tr>
                                <tr>
                                  <th>Tx 2</th>
                                  <td className="bg-greenPin"><a href="#">6.4 %</a></td>
                                  <td className="bg-yellow"><a href="#">0.5 %</a></td>
                                  <td className="bg-yellow"><a href="#">47</a></td>
                                  <td className="bg-yellow"><a href="#">0.5 %</a></td>
                                  <td className="bg-greenPin"><a href="#">59</a></td>
                                  <td className="bg-greenPin"><a href="#">59</a></td>
                                </tr>
                                <tr>
                                  <th>Tx 3</th>
                                  <td className="bg-greenPin"><a href="#">1.3 %</a></td>
                                  <td className="bg-redPin"><a href="#">5.5 %</a></td>
                                  <td className="bg-redPin"><a href="#">0.50</a></td>
                                  <td className="bg-greenPin"><a href="#">12</a></td>
                                  <td className="bg-yellow"><a href="#">3.3 %</a></td>
                                  <td className="bg-greenPin"><a href="#">88</a></td>
                                </tr>
                                <tr>
                                  <th>Tx 4</th>
                                  <td className="bg-yellow"><a href="#">12.3 %</a></td>
                                  <td className="bg-redPin"><a href="#">-3.5 %</a></td>
                                  <td className="bg-greenPin"><a href="#">0.95</a></td>
                                  <td className="bg-redPin"><a href="#">340</a></td>
                                  <td className="bg-greenPin"><a href="#">1.2 %</a></td>
                                  <td className="bg-greenPin"><a href="#">104</a></td>
                                </tr>
                                <tr>
                                  <th>Tx 5</th>
                                  <td className="bg-redPin"><a href="#">16.1 %</a></td>
                                  <td className="bg-redPin"><a href="#">-6.2 %</a></td>
                                  <td className="bg-greenPin"><a href="#">0.80</a></td>
                                  <td className="bg-redPin"><a href="#">120</a></td>
                                  <td className="bg-redPin"><a href="#">4.9 %</a></td>
                                  <td className="bg-greenPin"><a href="#">40</a></td>
                                </tr>      
                              </tbody>
                            </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- slide 3 start --> */}
                          <div className="carousel-item">
                            <div className="col-md-12 col-lg-12">
                              <div className="card pr-card mb30">
                                <div className="breadcrumbHdr card-header">
                                  <h5>Temperature Cockpit</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row align-items-center">
                                    <div className="col-md-6 col-lg-6 col-xl-6">
                                      <div className="card pr-card">
                                        <div className="inrCardHdr">
                                          <h5>Transformer 1</h5>
                                        </div>
                                        <div className="inrCardBody">
                                          <div id="app">
                                            <Chart
                                              options={chartOption}
                                              series={chartSeries}
                                              type="line"
                                              width="100%"
                                              height="260px"
                                              id=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6">
                                      <div className="card pr-card">
                                        <div className="inrCardHdr">
                                          <h5>Transformer 2</h5>
                                        </div>
                                        <div className="inrCardBody">
                                          <div id="transfmr2">
                                            <Chart
                                              options={chartOption}
                                              series={chartSeries}
                                              type="line"
                                              width="100%"
                                              height="260px"
                                              id=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6">
                                      <div className="card pr-card">
                                        <div className="inrCardHdr">
                                          <h5>Transformer 3</h5>
                                        </div>
                                        <div className="inrCardBody">
                                          <Chart
                                            options={chartOption}
                                            series={chartSeries}
                                            type="line"
                                            width="100%"
                                            height="260px"
                                            id=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6">
                                      <div className="card pr-card">
                                        <div className="inrCardHdr">
                                          <h5>Transformer 4</h5>
                                        </div>
                                        <div className="inrCardBody">
                                          <Chart
                                            options={chartOption}
                                            series={chartSeries}
                                            type="line"
                                            width="100%"
                                            height="260px"
                                            id=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <!-- slide 3 end --> */}
                          {/* <!-- slide 4 start --> */}

                          <div className="carousel-item">
                            <div className="col-md-12 col-lg-12">
                              <div className="card pr-card mb30">
                                <div className="inrCardHdr">
                                  <h5>Relays And Alerts List</h5>
                                </div>
                                <div className="inrCardBody">
                                {/* {isChartDataAvailableTwo ? (
                      <Plot
                        theme="streamlit"
                        data={chartData.series}
                        layout={chartData.options}
                        config={{
                          displayModeBar: true,
                          displaylogo: false,
                          responsive: true,
                          toImageButtonOptions: {
                            filename: "Busbar",
                          },
                          modeBarButtonsToAdd: [excelIcon],

                          modeBarButtonsToRemove: [
                            "select2d",
                            "lasso2d",
                            "autoscale",
                            "zoom2d",
                            "pan2d",
                            "zoomIn2d",
                            "zoomOut2d",
                            "autoScale2d",
                            "resetScale2d",
                            "hoverClosestCartesian",
                            "hoverCompareCartesian",
                          ],
                        }}
                        style={{ width:"100%", height: 650 }} // Adjust style based on your requirements
                      />
                    ) : (
                      <>
                        <Plot
                          data={emptyChartData.series}
                          layout={emptyChartData.options}
                          config={{
                            displayModeBar: true,
                            displaylogo: false,
                            responsive: true,
                            toImageButtonOptions: {
                              filename: "Busbar",
                            },
                            // modeBarButtonsToAdd: [excelIcon],

                            modeBarButtonsToRemove: [
                              "toImage",
                              "select2d",
                              "lasso2d",
                              "autoscale",
                              "zoom2d",
                              "pan2d",
                              "zoomIn2d",
                              "zoomOut2d",
                              "autoScale2d",
                              "resetScale2d",
                              "hoverClosestCartesian",
                              "hoverCompareCartesian",
                            ],
                          }}
                          type="heatmap"
                          style={{ width: "100%", height: 650 }}
                        />
                      </>
                    )} */}
                                  <table
                                    className="table dtr-inline eleParaTbl"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Transformer Name</th>
                                        <th className="text-center td_width">
                                          OTI Alarm
                                        </th>
                                        <th className="text-center td_width">
                                          WTI Alarm
                                        </th>
                                        <th className="text-center td_width">
                                          OTI Trip
                                        </th>
                                        <th className="text-center td_width">
                                          WTI Trip
                                        </th>
                                        <th className="text-center td_width">Buchhlz</th>
                                        <th className="text-center td_width">PRV</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th>Tx 1</th>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-redPin">Operated</td>
                                        <td className="bg-greenPin">Healthy</td>
                                      </tr>
                                      <tr>
                                        <th>Tx 2</th>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-redPin">Operated</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                      </tr>
                                      <tr>
                                        <th>Tx 3</th>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-redPin">Operated</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                      </tr>
                                      <tr>
                                        <th>Tx 4</th>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                      </tr>
                                      <tr>
                                        <th>Tx 5</th>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-greenPin">Healthy</td>
                                        <td className="bg-redPin">Operated</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- slide 4 end --> */}
                          <div className="carousel-item">
                            <div className="col-md-12 col-lg-12">
                              <div className="card pr-card mb30">
                                <div className="inrCardHdr">
                                  <h5>
                                    Energy Consumption Benchmark VS Actuals
                                  </h5>
                                </div>
                                <div className="inrCardBody">
                                  <div id="barchart">
                                  <ReactApexChart
                                    options={chartDataMain.options}
                                    series={chartDataMain.series}
                                    type="bar"
                                    height={650}
                                  />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- <button className="carousel-control-prev" data-bs-target="#carouselExample" type="button" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" data-bs-target="#carouselExample" type="button" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button> --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <Sidebar
        locationData={locationData}
        assetData={assetData}
        changeLocation={changeLocation}
        changeAsset={changeAsset}
        handleSubmitData={handleSubmitDataOnClick}
        selectedLocation={selectedLocation}
        selectedAsset={selectedAsset}
      /> */}
    </>
  );
}

export default Fleet;
