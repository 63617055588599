import React from 'react'
import Chart from "react-apexcharts";

const LineChart = (props) => {
    const { chartSeries, chartOption } = props;
    return (
        <>
            <div className="map col-md-12 col-lg-12">
                <div className="card pr-card mb30">
                    <div className="card-body">
                        <div className="app">
                            <div className="row">
                                <div className="mixed-chart">
                                    <Chart
                                        options={chartOption}
                                        series={chartSeries}
                                        type="line"
                                        width="100%"
                                        height="350px"
                                        id=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LineChart
